<template>
  <div class="tabbarContent">
    <div class="mydiv" v-if="active !== 'make-money'"></div>
    <div class="tabbar">
      <div v-for="(item, index) in tabelList" :key="index">
        <div v-if="!item.isSpecial" @click="handleTab(item)">
          <img
            :src="
              active === item.pagePath ? item.selectedIconPath : item.iconPath
            "
          />
          <span :class="{ on: active === item.pagePath }">{{ $t(item.text) }}</span>
        </div>
        <div v-else class="special" @click="handleTab(item)">
          <div class="tabbar_3">
            <div class="tabbar_bg">
              <img :src="item.selectedIconPath" alt="">
            </div>
          </div>
          <span :class="{ on: active === item.pagePath }">{{ $t(item.text) }}</span>
        </div>
      </div>
    </div>
    <van-dialog
      cancel-button-text="go"
      :title="$t('Login.loginButton')"
      :confirm-button-text="$t('Login.goLoginText')"
      close-on-click-overlay
      v-model="show"
      @confirm="handleDialog"
    >
    </van-dialog>
  </div>
</template>
<script>
import Modelu from '@/utils/Module'
import { REQUEST_API } from '@/http/api'
import Cookies from 'js-cookie'
import { Dialog } from 'vant'
export default {
  name: 'Tabbar',
  props: {
    active: {
      type: String,
      required: false,
      default: 'goods',
    },
    language: {
      type: String,
      required: false,
      default: Cookies.get('language') || 'idn',
    },
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      tabelList: [
        {
          text: 'newTabbar.goods',
          pagePath: 'goods',
          iconPath: require('@/assets/Img/tabber/home.png'),
          selectedIconPath: require('@/assets/Img/tabber/home_on.png'),
          isSpecial: false,
        },
        {
          text: 'newTabbar.order',
          pagePath: 'cart',
          iconPath: require('@/assets/Img/tabber/order.png'),
          selectedIconPath: require('@/assets/Img/tabber/order_on.png'),
          isSpecial: false,
        },
        {
          text: 'newTabbar.shop',
          pagePath: 'make-money',
          iconPath: require('@/assets/Img/tabber/ZY_0022_dd.png'),
          selectedIconPath: require('@/assets/Img/tabber/ZY_0022_dd.png'),
          isSpecial: true,
        },
        {
          text: 'newTabbar.kefu',
          pagePath: 'kefu',
          iconPath: require('@/assets/Img/tabber/wealth.png'),
          selectedIconPath: require('@/assets/Img/tabber/wealth_on.png'),
          isSpecial: false,
        },
        {
          text: 'newTabbar.user',
          pagePath: 'user',
          iconPath: require('@/assets/Img/tabber/my.png'),
          selectedIconPath: require('@/assets/Img/tabber/my_on.png'),
          isSpecial: false,
        },
      ],
      show: false,
      pagePath: '',
    }
  },
  computed: {},
  mounted() {
    // TO DO
    // 暂时不用这个
    // console.log(this.$router.history.current.name)
    // this.getVersions()
  },
  watch: {
    language(val) {
      this.tabelList[0].text = `${this.$t('newTab.home')}`
      this.tabelList[1].text = `${this.$t('newTab.money')}`
      this.tabelList[2].text = `${this.$t('newTab.shop')}`
      this.tabelList[3].text = `${this.$t('newTab.order')}`
      this.tabelList[4].text = `${this.$t('newTab.my')}`
    },
  },
  created() {},
  methods: {
    handleTab(item) {
      // this.$router.push(item.pagePath)
      // if (
      //   !localStorage.getItem('isLogin') ||
      //   !localStorage.getItem('user-info')
      // ) {
      //   // this.show = true
      //   this.pagePath = item.pagePath
      //   return false
      // }
      if(item.pagePath == 'kefu'){
        return this.$store.dispatch("toService")
      }

      this.$router.push(item.pagePath)
    },
    handleDialog() {
      this.show = false
      // this.$router.replace('login')
      this.$router.push('login')
    },
    /** api **/
    getVersions() {
      let form = {
        cmd: '100005',
        channel: Modelu.signStrFunc(),
      }
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            if (JSON.parse(sessionStorage.getItem('version-dialog-show'))) {
              if (localStorage.getItem('version')) {
                if (
                  r.data.notifyList[0].data.version ===
                  localStorage.getItem('version')
                ) {
                  localStorage.setItem(
                    'version',
                    r.data.notifyList[0].data.version
                  )
                } else {
                  Dialog.confirm({
                    title: '',
                    message: this.$t('Hint.updateText'),
                    cancelButtonText: this.$t('My.Button.One'),
                    confirmButtonText: this.$t('My.Button.Two'),
                  })
                    .then(() => {
                      // on confirm
                      let src = 'http://file.xiud55.top/yn_look.apk'
                      let iframe = document.createElement('iframe')
                      iframe.style.display = 'none'
                      iframe.src = `javascript: '<script>location.href="${src}"<\/script>'`
                      document
                        .getElementsByTagName('body')[0]
                        .appendChild(iframe)
                      setTimeout(function () {
                        document.body.removeChild(iframe)
                      }, 1000)
                      localStorage.setItem(
                        'version',
                        r.data.notifyList[0].data.version
                      )
                    })
                    .catch(() => {
                      sessionStorage.setItem('version-dialog-show', false)
                    })
                }
              }
            } else {
              localStorage.setItem('version', r.data.notifyList[0].data.version)
            }
          } else {
            Toast(this.$t('Hint.failed'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }, // 查询版本号
  },
}
</script>
<style lang="less" scoped>
.tabbarContent {
  width: 100%;
  // background: transparent;
  background-color: #fff;
  > .mydiv {
    width: 100%;
    height: calc(50rem / 16);
    background-color: #f4f8fb;
  }
  .tabbar {
    width: 100%;
    height: calc(50rem / 16);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: flex;
    font-size: calc(12rem / 16);
    justify-content: space-around;
    background-color: #fff;
    // background: url('../assets/Img/tabber/ZY_0027_yjd.png') no-repeat;
    background-size: 100% 100%;
    border-top: calc(1rem / 16) solid #ededed;
    > div {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > img {
          width: calc(20rem / 16);
          margin-bottom: calc(3rem / 16);
        }
        > span {
          color: #a19fa8;
          &.on {
            // color: #f5d765;
            color: #333;
          }
        }
      }
      > .special {
        width: 100%;
        height: 100%;
        position: absolute;
        top: calc(-24rem / 16);
        left: 50%;
        transform: translate(-50%, 0%);
        width: calc(60rem / 16);
        height: calc(68rem / 16);
        border-radius: 50px;
        background: url('../assets/Img/tabber/ZY_0022_dd.png') no-repeat;
        background-size: 100% 100%;
        font-size: calc(12rem / 16);
        color: #000;
        > span {
          width: calc(75rem / 16);
          // margin-top: calc(4rem / 16);
          display: block;
          text-align: center;
        }
      }
    }
  }
}
.tabbar_3{
  width: calc(40rem / 16);
  height: calc(40rem / 16);
  border-radius: 50%;
  padding: calc(4rem / 16);
  box-shadow: 0 0 10px #afafaf;
  margin-bottom: calc(4rem / 16);
  background-color: #fff;
  .tabbar_bg{
    background-color: var(--navBg);
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: calc(28rem / 16);
      height: calc(28rem / 16);
    }
  }
}
</style>
